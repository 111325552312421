.timeline-flex {
    display: flex;
    justify-content: space-between;
}

.timeline-card {
    width: 32.2%
}

.sub-header {
    font-size: 10px;
    margin-bottom: 2%;
}

.header {
    margin-bottom: 2%;
    color: rgba(49, 89, 117, 1);
    font-weight: 600;
    font-size: 16px;
}

.timeline-content {
    font-size: 12px;
}