.timeline-flex {
    display: flex;
    justify-content: space-between;
}

.timeline-card {
    width: 32.2%
}

.sub-header {
    font-size: 10px;
    margin-bottom: 2%;
}

.header {
    margin-bottom: 2%;
    color: rgba(49, 89, 117, 1);
    font-weight: 600;
    font-size: 16px;
}

.timeline-content {
    font-size: 12px;
}

.flex {
    display: flex;
    justify-content: flex-start;
}

.card {
    border-radius: 4px;
    overflow: hidden;
}

.stakeholder-card {
    border-right: 1px solid rgb(215, 215, 215);
    
}

.stakeholder-wrapper .card {
    padding: 0%
}

.stakeholder-card-header-one {
    background-color: rgba(115, 195, 179, 0.2);
    padding: 15px;
    font-weight: 700;
    color: rgba(49, 89, 117, 1);
    font-size: 14px;
    
}

.stakeholder-card-header-two {
    background-color: rgba(245, 195, 123, 0.2);
    padding: 15px;
    font-weight: 600;
    color: rgba(49, 89, 117, 1);
    font-size: 14px;
}

.stakeholder-card-header-three {
    background-color: rgba(189, 209, 247, 0.2);
    padding: 15px;
    font-weight: 600;
    color: rgba(49, 89, 117, 1);
    font-size: 14px;
}

.stakeholder-wrapper .col-4 {
    padding: 0%;
}

.stakeholder-card-body {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 2%;
}

.stakeholder-head {
    font-weight: 600;
    margin-bottom: 1%;
    font-size: 16px;
    
}

.stakeholder-content {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5%;
}

.flex {
    display: flex;
    justify-content: flex-start;
}

.response-container {
    margin-top: 3%;
}

.table td {
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

.response-table tbody {
    font-weight: 600;
}

.content-width {
    width: 70%;
}

