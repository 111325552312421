.custom-table-one {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .custom-table-one thead tr {
    background-color: #31597580;
    color: #315975;
    text-align: left;
    font-weight: bold;
  }
  
  .custom-table-one th,
  .custom-table-one td {
    padding: 12px 15px;
  }
  
  .custom-table-one tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  /* .custom-table-one tbody tr:nth-of-type(even) {
 
    display: table-row;
}

.custom-table-one tbody tr:nth-of-type(even):has(td:nth-of-type(3)):not(:has(td:nth-of-type(4))) {
    background-color: red;
} */
  .custom-table-one tbody tr:nth-of-type(even) {
    /* background-color: #e91010; */
  }
  
  .custom-table-one tbody tr:last-of-type {
    border-bottom: 2px solid #31597580;
  }
  
  .custom-table-one tbody tr.active-row {
    font-weight: bold;
    color: #315975;
  }
  