.flex-box {
    display: flex;
    justify-content: flex-start;
}

.flex-align {
    display: flex;
    align-items: center;
}

.status-box-active {
    background-color: rgba(32, 159, 0, 0.2);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: rgba(32, 159, 0, 1)
}

.status-box-inactive {
    background-color: rgba(179, 179, 179, 0.2);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: rgba(119, 119, 119, 1);
}

.status-circle-active {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: rgba(32, 159, 0, 1)
}

.status-circle-inactive {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: rgba(119, 119, 119, 1);
}

.remove-bottom-margin {
    margin-bottom: 0%;
}

.container-bottom-margin {
    margin-bottom: 5%;
}

.link-box {
    background-color: rgb(211, 211, 211);
    padding-left: 0.5%;
    padding-right: 0.5%;
    padding-bottom: 0.2%;
    padding-top:0.2%;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 5px;
}

.link {
    font-size: 13px;
}

.copy-icon {
    margin-left: 1%;
    font-size: 17px;
    padding-top: 3px;
}